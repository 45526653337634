@import 'styles/variables.less';

.br-order-details__icons__sub-title,
.br-new-order__order_types__cod .ant-radio-wrapper span,
.br-order-details__cod-form-item .ant-input-prefix,
.br-order-details__package-details__open-packages-options span,
.br-order-details__allow-customer-open-packages-row span,
.br-order-details__cod-form-item__label__sub-title .ant-radio-wrapper span {
  font-family: @lato-font-400;
  color: @text-color;
}

.br-order-details__cod-form-item .ant-input-suffix {
  margin: 0 4px;
}

.br-order-details .ant-checkbox-inner {
  border: 0.5px solid @text-color-30;
  box-shadow: 0px 1px 2px 0px @box-shadow;
  border-radius: 4px;
}

.br-order-details__icons__title {
  font-family: @poppins-font-600;
  color: @text-color;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
  display: flex;
  white-space: nowrap;
}

.br-order-details__cod-form-item__label__sub-title .ant-radio-group {
  padding-top: 11px;
}

.br-form-item-label__cod_title {
  color: @text-color;
  font-size: 16px;
  font-family: @poppins-font-600;
  font-weight: 500;
}

.br-order-details__divider.ant-divider-horizontal {
  margin-top: 0;
  margin-bottom: 14px;
}

.br-order-details__allow-customer-open-packages-row span,
.br-order-details__allow-customer-open-packages-row .ant-checkbox-wrapper,
.br-order-details__sign-and-return span:nth-child(2) {
  display: flex;
}

.br-order-details__allow-customer-open-packages__content__title,
.br-order-details__sign-and-return span:nth-child(2) {
  align-items: center;
  font-size: 13px;
}

.br-order-details__sign-and-return .anticon {
  margin-left: 8px;
}

.br-order-details__allow-customer-open-packages-row .ant-checkbox {
  margin-top: 3px;
}

.br-order-details__allow-customer-open-packages-row
  .ant-checkbox-wrapper
  .anticon {
  margin-left: 5px;
}

.br-order-details__allow-customer-open-packages-row p {
  color: @text-color;
  font-size: 13px;
}

.br-order-details__allow-customer-open-packages-column.ant-row.ant-form-item {
  margin-bottom: 0;
}

.br-order-details__allow-customer-open-packages__content {
  display: flex;
  flex-direction: column;
  color: #2d3859;
  font-size: 13px;
  font-family: @lato-font-400;
  font-weight: 500;
}

.br-order-details
  .br-order-details__order_types
  .ant-radio-button-wrapper::before {
  display: none;
}

.br-order-details__order_types
  .ant-radio-button-wrapper:not(.ant-radio-button-checked) {
  height: fit-content;
  border: 0;
  text-align: center;
  margin-right: 12px;
  border-radius: 8px;
  flex: 1;
}

.br-order-details__order_types
  .ant-radio-button-wrapper:not(.ant-radio-button-checked):last-child {
  margin-right: 0;
}

.br-order-details__icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 80px;
  padding-top: 17px;
}

.br-order-details__icons__with-options {
  height: auto;
  margin-bottom: 13px;
}

.br-order-details__order_types .ant-radio-button-wrapper {
  background: @background-color-5;
}

.br-order-details__order_types .ant-radio-button-checked,
.br-order-details__order_types
  .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
  border-radius: 8px;
}

.br-order-details__order_types .ant-radio-button-checked,
.br-order-details__order_types
  .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled,
.br-package-details__types
  .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
  border: 2px solid @border-color-16;
}

.br-order-details__order_types .br-new-order__order_types__cod {
  text-align: start;
  padding-top: 11px;
}

.br-order-details__package-details,
.br-order-details__delivery-package,
.br-new-order__card-content-titles {
  display: flex;
}

.br-order-details__delivery-package,
.br-order-details__return-package {
  flex: 1;
}

.br-order-details__package-details > div {
  flex: 1;
}

.br-form-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.br-form-group {
  margin-right: 22px;
}

.br-order-details__package-details .br-good-value__form-group {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 20px;
  > div {
    flex: content;
    height: auto;
  }
}

.br-order-details__delivery-package .br-form-group:last-child {
  margin-right: 0px;
}

.br-order-details__icons .anticon svg {
  height: 28px;
  width: 47px;
}

.br-order-details__icons__sub-title {
  font-size: 10px;
  font-weight: 500;
  height: auto;
  line-height: 13px;
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  padding-left: 8px;
  margin-bottom: 0;
}

.ant-radio-button-wrapper-checked .br-order-details__icons__title {
  color: @green-icon-color;
}

.br-new-order__order_types__cod .ant-radio-group {
  display: flex;
  flex-direction: column;
}

.br-new-order__order_types__cod .ant-radio-wrapper .ant-radio-inner {
  opacity: 1;
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: white;
  border-color: @green-icon-color;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  -webkit-transition: none;
  transition: none;
}

.br-new-order__order_types__cod .ant-radio-wrapper span {
  height: 14px;
  font-size: 10px;
  font-weight: 500;
}

.br-order-details__cod-form-item .ant-input-affix-wrapper {
  height: 32px;
  border-radius: 2px;
  padding: 0;
  max-width: 209px;
}

.br-order-details .br-order-details__cod-form-item .ant-input-prefix {
  padding: 10px;
  background: @border-color-20;
  color: @text-color;
  border: 1px solid @border-color-19;
  display: flex;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
}

.br-order-details__cod-form-item__label__sub-title {
  font-family: @lato-font-400;
  color: @text-color-31;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.br-order-details__cod-form-item__label__sub-title .ant-radio-wrapper {
  color: @text-color;
  font-size: 10px;
  font-weight: 500;
}

.br-order-details__icons .ant-radio-group {
  display: flex;
  flex-direction: column;
  justify-self: start;
  width: 100%;
}

.br-order-details__cod-form-item__label__sub-title .ant-radio-group {
  margin-bottom: 9px;
}

.br-new-order__card-content-titles span {
  flex: 1;
}

.br-order-details__order_types.ant-radio-group {
  padding-bottom: 13px;
  display: flex;
}

.br-order-details__package-details__open-packages-options span,
.br-order-details__allow-customer-open-packages-row span {
  font-size: 13px;
  font-weight: 500;
}

.br-request-pickup-modal__create-pickup-location {
  display: flex;
  flex-direction: column;
}

.br-request-pickup-modal__create-pickup-location .ant-row.ant-form-item {
  flex: unset;
}

.br-request-pickup-modal__create-pickup-location .ant-btn.br-add-button {
  justify-content: flex-end;
  margin-bottom: 20px;
}

.br-form-group > * {
  flex: 1 1 auto;
}

.br-new-order-card__content ::-webkit-scrollbar {
  width: 4px;
}

.br-new-order-card__content ::-webkit-scrollbar-thumb {
  border-radius: 2.5px;
}

.br-new-order-card__content ::-webkit-scrollbar-track {
  background-color: @background-color-17;
  margin: 5px;
}

.br-package-details__types .ant-radio-button-wrapper {
  border-radius: 4px;
  border-left: 1px solid @border-color-3;
  margin-right: 8px;
  padding-left: 25px;
  padding-right: 0;
}

.br-package-details__types .ant-radio-button-wrapper:last-child,
.br-package-details__types .ant-radio-button-wrapper:first-child {
  border-radius: 4px;
}

.br-package-details__types
  label.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.br-package-details__types
  label.ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

.br-package-details__types .ant-radio-button-wrapper .anticon {
  margin-right: 10px;
}

.br-package-details__types.ant-radio-group .ant-radio-button-wrapper {
  display: flex;
  width: 150px;
  align-items: flex-start;
}

.br-package-details__types .ant-radio-button-wrapper {
  align-items: center;
}

.br-package-details__types .ant-radio-button-wrapper span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: @lato-font-400;
  font-weight: 900;
}

.br-package-details__types
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: @background-color-50;
}

.br-package-details__types
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: @background-color-16;
}

.br-package-details__types .ant-radio-button-wrapper span span {
  color: @text-color;
}

.br-package-details__types .ant-radio-button-wrapper-checked .anticon path {
  fill: @background-color-16;
}

.br-package-details__types .anticon path {
  fill: @text-color;
}

.br-order-details__item-count-title-with-link .br-form-item-label__title span {
  width: 100%;
}

.br-order-details__cod-form-item__label__sub-title div.ant-radio-group {
  display: flex;
  flex-direction: row;
  margin-bottom: 9px;
  padding-top: 11px;
}

.br-order-details__cod-exchange
  .br-forms-new-input
  .br-form-with-sub-title.ant-form-item
  > .ant-form-item-label {
  height: 57px;
}

.br-order-details__cod-form-item
  .br-form-item-label
  .br-form-item-label__title
  > span {
  width: 100%;
}

.br-form-group_types-bulky.ant-row.ant-form-item {
  min-height: unset;
}

.br-form-group_types-bulky span svg {
  margin-left: 8px;
  margin-top: 4px;
}

.br-form-group_types-bulky .ant-radio-wrapper span:nth-child(2) {
  display: flex;
}

@media only screen and (max-width: 992px) {
  .br-order-details__order_types
    .ant-radio-button-wrapper:not(.ant-radio-button-checked) {
    width: auto;
  }

  .br-order-details__order_types.ant-radio-group {
    display: flex;
    flex-direction: column;
  }

  .br-order-details__order_types .ant-radio-button-wrapper {
    margin-bottom: 10px;
  }

  .br-order-details__delivery-package {
    flex-direction: column;
  }

  .br-form-group:not(:last-child) {
    margin-right: 0;
  }

  .br-order-details__allow-customer-open-packages-row.ant-row.ant-form-item {
    margin-bottom: 0;
  }

  .br-form-with-sub-title.ant-form-item > .ant-form-item-label {
    height: auto;
  }

  .br-order-details__icons .anticon svg {
    width: 33px;
  }
}
