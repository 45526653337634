@import 'styles/variables';

.br-filters-search__container {
  .br-deliveries-search__checkbox.ant-row.ant-form-item {
    margin-top: 20px;
  }

  &.ant-collapse {
    box-shadow: @border-all-sides;
    background-color: @gray-200;

    border-radius: 16px;
    margin-bottom: @spacing-xl;

    & > .ant-collapse-item {
      & > .ant-collapse-header {
        margin-bottom: 0;
        padding: @spacing-lg;

        & > .ant-collapse-header-text {
          flex: 1;

          & > .br-filters-search__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            height: calc(68px - (@spacing-lg * 2));

            & > h3.br-filters-search__header-title {
              margin: 0;
            }

            & > .br-filters-search__header-actions {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: @spacing-sm;

              height: 100%;

              & > button.ant-btn {
                height: 100%;
                width: 96px;
                padding-inline: 16px;
              }
            }
          }
        }
      }

      & > .ant-collapse-content {
        background-color: @white;
        box-shadow: @border-all-sides;

        padding: @spacing-lg;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;

        & > .ant-collapse-content-box {
          padding: 0;

          & > form.ant-form > .br-filters-search__row:not(:last-child) {
            margin-bottom: @spacing-md;
          }
        }
      }
    }
  }
}
