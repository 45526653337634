@import 'styles/variables';

.br-compensation__form-departments-section {
  border: 1px solid @gray-200;
  margin-top: 8px;
  border-radius: 8px;
  // background-color: red;

  .br-compensation__order-type-radio {
    padding: 12px 16px;
  }

  .ant-spin-container {
    padding: 0 16px;
  }

  .br-compensation__extra-checks {
    .br-compensation__extra-checks-items {
      margin: 16px 0;
      display: flex;
      gap: 26px;
      .br-compensation__extra-checks-item {
        display: flex;
        width: 50%;
        flex-direction: column;
      }

      .br-compensation__extra-checks-err {
        color: red;
      }
    }
  }

  .ant-checkbox-group {
    width: 100%;
  }
  .ant-spin-nested-loading {
    height: fit-content;
  }
}

.br-compensation__form-section-header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border-bottom: 1px solid @gray-200;
}

.br-compensation__form-order-details-leftside {
  padding-top: 12px;
}

.br-compensation__form-dep-liability {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;

  p {
    margin-bottom: 0;
  }

  > span {
    display: flex;
    flex-direction: column;
  }

  .br-compensation__form-dep-liability-inputs,
  .br-compensation__form-dep-liability-label {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }

  .br-compensation__liability-checkboxes .ant-checkbox-group {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    min-width: 182px;
  }

  .ant-row.ant-form-item {
    max-width: 160px;
  }

  .ant-divider-horizontal {
    margin: 0 0;
  }
}

.br-compensation-info-content-container {
  .br-compensation__form-dep-liability
    .br-compensation__form-dep-liability-inputs {
    .ant-row.ant-form-item {
      min-height: 36px;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}
