@import 'styles/variables';

.br-table-filters {
  position: relative;
  .ant-radio-button-wrapper {
    height: 36px;
    white-space: nowrap;
  }

  .ant-radio-button-wrapper:first-child:last-child {
    border-radius: 0;
  }

  .ant-radio-group div:first-child .ant-radio-button-wrapper {
    border-radius: 4px 0 0 4px;
  }

  .ant-radio-group div:last-child .ant-radio-button-wrapper {
    border-radius: 0 4px 4px 0;
  }

  & .ant-radio-group {
    display: flex;
  }

  & .ant-radio-button-wrapper-checked {
    border-color: @gray-300;
  }

  &
    .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: @gray-300;
  }
  &
    .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):focus-within {
    box-shadow: unset;
  }
}

.br-table-component:not(.br-table__small-mobile) {
  .ant-radio-group {
    filter: drop-shadow(@shadow-xs);
  }
}

.br-table-filters .ant-dropdown-trigger .ant-radio-button-checked {
  background-color: @gray-50;
}

.br-table__small-mobile .br-table-filters {
  .ant-dropdown-trigger .ant-radio-button-checked,
  .ant-dropdown-trigger.ant-dropdown-open .ant-radio-button-wrapper {
    border-radius: 0;
    box-shadow: 0px 0px 0px 4px @teal-200, inset 1px 0px 0px @teal-300,
      inset 0px -1px 0px @teal-300, inset 0px 1px 0px @teal-300;
    border: none;
  }
  .ant-dropdown-trigger .ant-radio-button-checked,
  #radio-button0.ant-dropdown-trigger.ant-dropdown-open
    .ant-radio-button-wrapper {
    border-radius: 4px 0px 0px 4px;
  }
  .ant-dropdown-trigger:last-child.ant-dropdown-open
    .ant-radio-button-wrapper:last-child {
    border: 1px solid @teal-400;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 4px @teal-200;
    border-radius: 0px 4px 4px 0px;
  }
}

.br-date-picker-radio-button .ant-radio-button {
  border-radius: 4px;
}

.br-date-picker-radio-button.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child {
  border-color: @gray-300;
}

.br-date-picker-radio-button.ant-radio-button-wrapper-checked:not(
    [class*=' ant-radio-button-wrapper-disabled']
  ).ant-radio-button-wrapper:first-child {
  border-right-color: @gray-300;
}

.br-date-picker-radio-button.br-confirmed-at-drop-down-open
  .ant-radio-button-checked {
  box-shadow: 0px 0px 0px 4px @teal-200, inset 1px 0px 0px @teal-300,
    inset 0px -1px 0px @teal-300, inset 0px 1px 0px @teal-300;
}

.br-table-filters {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 54vw;
  & .br-date-picker__range-picker-preset {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  & .br-date-picker__range-picker-preset__suffix-container > svg {
    display: none;
  }
  & .ant-radio-group {
    display: flex;
    overflow-x: scroll;
  }

  & .ant-radio-button-wrapper {
    display: flex;
  }

  & .ant-radio-button-wrapper.br-date-picker-radio-button {
    padding: 0;
  }

  & .ant-radio-button-wrapper span {
    display: flex;
    align-items: center;
    .font({.button()});
  }

  & .ant-radio-button-wrapper:hover {
    color: @text-gray-dark;
  }

  & .ant-radio-button span {
    padding: 8px 12px 8px 16px;
  }
}

.br-table-filters__table-without-search {
  max-width: 58vw;
}

.br-table__small-mobile .br-table-filters__tags {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.br-table__small-mobile .br-table-filters {
  max-width: 100%;
  margin-top: 12px;
  & .ant-radio-group {
    overflow-x: auto;
    border-spacing: 0;
    padding: 3px;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:horizontal {
    height: 14px;
  }
}

.br-table-filters__not-active-arrow path {
  fill: @gray-300;
}

.br-table-filters__left-arrow {
  margin-left: 14px;
  margin-right: 30px;
}

.br-table-filters__right-arrow {
  margin-right: 14px;
}

.br-table-component:not(.br-table__small-mobile)
  .br-table-filters__left-fade-in:before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  background-image: linear-gradient(to right, rgba(white, 0.9), rgba(black, 0));
  width: 37px;
  height: 100%;
}

.br-table-filters__right-fade-in:after {
  content: '';
  position: absolute;
  right: 0;
  background-image: linear-gradient(to left, rgba(white, 0.9), rgba(black, 0));
  width: 63px;
  height: 100%;
}

.br-filter-dropdown .ant-dropdown-menu {
  width: fit-content;
  padding: 0;
  box-shadow: @shadow-md;
}

.br-filter-dropdown__with-note .ant-dropdown-menu {
  width: fit-content;
}

.br-table-filter__menu-content {
  max-height: 332px;
  min-width: 400px;
  overflow-y: auto;
  padding: 10px;
  & .ant-select {
    width: 100%;
  }

  & .ant-select-selector,
  .ant-picker-range {
    height: 36px;
    width: 100%;
  }
}

.br-filter-dropdown {
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: @white;
  }
  .ant-select-item {
    padding: 0px;
  }
}

.br-table-filter__menu-header {
  background: @bg-gray-light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  box-shadow: @border-bottom;
  & .ant-btn {
    height: 28px;
    box-shadow: @shadow-xs;
  }

  & .ant-btn:disabled {
    border: 1px solid @gray-100;

    span {
      color: @text-gray-light;
    }
  }
  & .ant-btn:first-child {
    width: 58px;
    height: 28px;
  }
  & .ant-btn:last-child {
    width: 53px;
    height: 28px;
  }
}

.br-table-filter__menu-header__title {
  color: @text-gray;
  padding: 0 20px;
  white-space: nowrap;
}

.br-table-filter__menu-content__input-text {
  padding: 12px 6px 12px 12px;
}

.br-orders-filter__cities-menu .br-table-filter__menu-content__input-text {
  padding: 12px 12px 12px 12px;
}

.br-table-filter__menu-content__input-text--note {
  color: @teal-700;
  background-color: @teal-100;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 16px;
  display: flex;
}

.br-table-filter__menu-content__input-text--note__icon.anticon {
  border-radius: 28px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  background: @teal-300;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
  }

  path {
    fill: transparent;
  }
}

.br-table-filter__menu-content__radio-menu.ant-radio-group {
  display: flex;
  flex-direction: column;
  padding: 10px;

  & .ant-radio-wrapper:not(:last-child) {
    margin-bottom: 10px;
  }

  & span {
    color: @text-gray-dark;
  }
}

.br-table-filter__menu-content__title {
  color: @gray-500;
  margin-bottom: 4px;
  text-transform: uppercase;
  display: inline-block;
}

.br-table-filter__menu-content__title:not(:first-child) {
  margin-top: 12px;
}

.br-table-filter__menu-content__input-text--note__subtitle {
  color: @gray-500;
}

.br-orders-filter__dates-with-states {
  .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
    display: block !important;
    width: 8px !important;
    background-color: @gray-50 !important;
    box-shadow: @border-left !important;
    border-radius: 14px !important;
  }
  .rc-virtual-list-scrollbar-thumb {
    background-color: @gray-200 !important;
    border-radius: 14px !important;
  }
}

.br-table__small-mobile .br-table-filters {
  margin: 12px 16px 0 16px;

  .ant-dropdown-trigger .ant-radio-button-wrapper svg {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: @gray-200;
    border: 4px solid @white;
    border-radius: 14px;
  }
}

.br-table-filters .ant-checkbox-wrapper {
  width: 100%;
}

.br-table-filters .br-confirmed-at-drop-down-open .ant-radio-button-checked {
  background-color: @gray-50;
}

.br-confirmed-at-drop-down-open.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover::before {
  background-color: @gray-300;
}

.br-table-filters
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  border-color: @gray-300;
}

.br-table-filters
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: @gray-300;
}

.br-table-filters .ant-radio-button-wrapper:hover {
  box-shadow: none;
  background-color: @gray-50;
}

.br-table__small-mobile .br-table-filters__right-fade-in:after {
  width: 0;
}

.br-table-component:not(.br-table__medium-mobile)
  .br-table-filters
  .ant-radio-group {
  max-width: 55vw;
}

html[dir='rtl'] .br-table__quick-filter-tabs .ant-tabs-nav-list {
  transform: translate(0, 0px) !important;
}

@media only screen and (max-width: @md) {
  .br-table-filters {
    max-width: 41vw;
  }
}

@media only screen and (max-width: @sm) {
  .br-table-filters {
    max-width: 29vw;
  }
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
}
