@import 'styles/variables';

.br-business-details .br-information-table {
  margin-bottom: 15px;
}

.br-business-details__edit-button {
  display: flex;
  justify-content: flex-end;
}

.br-business-details__edit-button .ant-btn {
  width: 100px;
  border-radius: 4px;
}

.br-teams__table .ant-tag-green {
  background-color: @background-color-22;
  color: @text-color-29;
}

.br-teams__table .ant-tag-yellow {
  background-color: @background-color-47;
  color: @text-color-43;
}

.br-teams__table {
  margin-bottom: 50px;
}

.br-business-details__promotions-table {
  .ant-table-thead .ant-table-cell {
    text-transform: capitalize;
  }
  margin-bottom: 16px;
}

.br-business-details > :nth-child(2) table tr td div {
  display: flex;
  align-items: center;
  > button {
    margin: auto;
    margin-left: 30px;
  }
}

.br-business-details__default-contacts-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.br-business-details__default-contacts {
  & .br-searchable-component__header > h4 {
    width: 100%;
  }
}

.br-business_details__no-document {
  color: @text-gray;
}

.br-business-details__international-shipping-indicator {
  width: 12px;
  height: 12px;
  border: 4px solid @gray-300;
  border-radius: 50%;
  margin-right: 4px;

  &.br-business-details__international-shipping-enabled {
    border-color: @gray-500;
  }

  &.br-business-details__international-shipping-activated {
    border-color: @green-600;
  }
}

.br-business-details__international-shipping-actions {
  > button.ant-btn.br-button-component {
    margin-left: 8px;
  }
}

.br-teams-table__username {
  display: flex;
  align-items: center;
  gap: 4px;
}

.br-business-details__load-sllr-info-btn {
  margin-top: 30px;
}

.br-business_details__basic-sllr-info {
  margin-top: 30px;
}
