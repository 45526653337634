@import 'styles/variables';

.br-tasks-tab__filter-btn {
  font-family: @lato-font-700;
  color: #6c738a;
  border: 1px solid rgb(108, 115, 138);
  border-radius: 4px;
}

.br-tasks-tab__filter-btn__clicked,
.br-tasks-tab__filter-btn:focus {
  background: @background-color-16;
  border: 1px solid @border-color-16;
  color: @text-color-15;
}

.br-searchable-table .ant-typography.br-tasks-tab__table__title {
  font-size: 14px;
  width: 100%;
}

.br-tasks-tab__order-table {
  font-weight: 600;
}

.br-tasks-tab__order-filter {
  width: 30%;
}
