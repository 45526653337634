@import './fonts-classes.less';

.responsive(@maxWidth; @rules) {
  @media only screen and (max-width: @maxWidth) {
    @rules();
  }
}

.font(@rules) {
  @rules();
}

@lato-font-400: var(--lato--font-400);
@lato-font-700: var(--lato--font-700);
@lato-font-900: var(--lato--font-900);

@poppins-font-400: var(--poppins--font-400);
@poppins-font-500: var(--poppins--font-500);
@poppins-font-600: var(--poppins--font-600);

@header-height: 72px;
@content-width: 250px;

@text-color: #1f2937;
@text-color-3: #b0b8cb;
@text-color-4: #718096;
@text-color-5: #222b45;
@text-color-8: #ced0da;
@text-color-10: #000000;
@text-color-12: #a0aec0;
@text-color-14: #030303;
@text-color-15: #ffffff;
@text-color-21: #0000ff;
@text-color-22: #0091ff;
@text-color-27: #ff0000;
@text-color-29: #00b05c;
@text-color-30: #cbd5e0;
@text-color-31: #6c738a;
@text-color-32: #abafbd;
@text-color-33: #4a5568;
@text-color-34: #d5d7de;
@text-color-36: #eec201;
@text-color-37: #3b82f6;
@text-color-36: #eec201;
@text-color-37: #3b82f6;
@text-color-38: #4495dd;
@text-color-39: #00c092;
@text-color-40: #21a9b6;
@text-color-42: #8e8103;
@text-color-43: #d69e2e;
@text-color-44: #fb762b;
@text-color-45: #9ca3af;
@text-color-46: #1f2937;
@text-color-47: #4b5563;
@text-color-48: #0066ff;
@text-color-49: #374151;
@text-color-50: #202223;
@text-color-51: #00b05c;
@text-color-52: #0098a5;
@text-color-53: #12b76a;
@text-color-54: #667085;
@text-color-55: #292a31;
@text-color-56: #404044;
@text-color-57: #6b6c7e;
@text-color-58: #2d3859;
@text-color-59: #344064;
@text-color-60: #ff4c4c;
@text-color-61: #027a48;
@text-color-65: #991b1b;
@text-color-66: #111619;
@border-color: #9daec2;
@border-color-3: #d9d9d9;
@border-color-10: #ffffff;
@border-color-13: #d6dee7;
@border-color-14: #e8e8e8;
@border-color-15: #ff0000;
@border-color-16: #00b887;
@border-color-17: #cbd5e0;
@border-color-18: #e2e8f0;
@border-color-19: #cbd5e0;
@border-color-20: #e2e8f0;
@border-color-22: #eec201;
@border-color-23: #e5e7eb;
@border-color-24: #d1d5db;
@border-color-25: #6b7280;
@border-color-26: #f59e0b;
@border-color-27: #f6e05e;
@border-color-28: #00001533;
@border-color-29: #00b27c;
@border-color-30: #e4e7ec;
@background-color: #ffffff;
@background-color-2: #f8f8f9;
@background-color-5: #f7fafc;
@background-color-8: #d7dde4;
@background-color-15: #ff0000;
@background-color-16: #00b887;
@background-color-17: #f7fafc;
@background-color-18: #edf2f7;
@background-color-19: #fff5f5;
@background-color-20: #fefddf;
@background-color-21: #3b82f61a;
@background-color-22: #00b8871a;
@background-color-23: #6c738a1a;
@background-color-24: #ff00001a;
@background-color-25: #eec2011a;
@background-color-26: #fffff0;
@background-color-29: #3b82f6;
@background-color-30: #eec201;
@background-color-31: #00b887;
@background-color-32: #6c738a;
@background-color-33: #3b82f61a;
@background-color-34: #00b8871a;
@background-color-35: #6c738a1a;
@background-color-36: #ff00001a;
@background-color-37: #eec2011a;
@background-color-38: #e2e8f0;
@background-color-46: #faf7e0;
@background-color-47: #21a9b61a;
@background-color-48: #d7262c;
@background-color-49: #fbf5ea;
@background-color-50: #f2fbf9;
@background-color-51: #00b05c;
@background-color-52: #e5e7eb;
@background-color-53: #0b8c4e;
@background-color-54: #ebebeb;
@background-color-55: #6b7280;
@background-color-56: #fffbeb;
@background-color-57: #f9edee;
@background-color-58: #faf089;
@background-color-59: #f9fafb;
@background-color-60: #d1fadf;
@background-color-61: #f2f4f7;
@background-color-62: #33a000;
@background-color-62: #f3f4f6;
@background-color-63: #f9fafb;
@background-color-64: #0ba5ec;
@background-color-67: #4b84ee1a;
@background-color-76: #d9f4f8;
@background-color-77: #f2fafb;
@background-color-64: #0ba5ec;
@background-color-65: #f3fafb;
@box-shadow: #0000001a;
@box-shadow-4: #a0aec04d;
@box-shadow-5: #1d29391a;
@box-shadow-6: #1d29390d;
@tag-red-text-color: #ff0000;
@tag-red-background-color: #ffe5e5;
@tag-orange-text-color: #fb762b;
@tag-orange-background-color: #fb762b1a;
@tag-yellow-text-color: #d69e2e;
@tag-light-yellow-text-color: #ecc94b;
@tag-light-yellow-background-color: #fffff0;
@tag-yellow-background-color: #fffbe5;
@tag-green-text-color: #00b05c;
@tag-green-background-color: #00b05c1a;
@tag-light-gray-text-color: #a0aec0;
@tag-light-gray-background-color: #f7fafc;
@tag-dark-gray-text-color: #ffffff;
@tag-dark-gray-background-color: #a0aec0;
@red-icon-color: #e30613;
@green-icon-color: #00b887;
@timeline-step-icon-color: #eeeeee;
@tracking-icon-color: #e1e6ec;
@side-bar-icon-color: #abafbd;
@btn-primary-icon-color: #ffffff;
@tooltip-icon-background-color: #ced0da;
@tooltip-icon-background-color-2: #ff0000;
@scrollbar-thumb-background-color: #d5d7de;
@scrollbar-track-background-color: #e2e8f0;

@checked-switch-fill-color: #c6f6d5;
@ant-icon-down-color: #00000040;

//**** business col

// Color Palette for new design system

@gray-25: #fcfcfd;
@gray-50: #f9fafb;
@gray-100: #f2f4f7;
@gray-200: #e4e7ec;
@gray-300: #d0d5dd;
@gray-400: #98a2b3;
@gray-500: #667085;
@gray-600: #475467;
@gray-700: #344054;
@gray-800: #1d2939;
@gray-900: #111619;

@red-25: #fffbfa;
@red-50: #fef3f2;
@red-100: #fee4e2;
@red-200: #fecdca;
@red-300: #fda29b;
@red-400: #f97066;
@red-500: #f04438;
@red-600: #e30613;
@red-700: #b42318;
@red-800: #7a271a;
@red-900: #330000;

@teal-25: #fafdfe;
@teal-50: #f3fafb;
@teal-100: #ecfafc;
@teal-200: #d9f4f8;
@teal-300: #9fdee4;
@teal-400: #1dbdcd;
@teal-500: #0098a5;
@teal-600: #137a84;
@teal-700: #0b5d66;
@teal-800: #14494e;
@teal-900: #083136;

@yellow-25: #fffcf5;
@yellow-50: #fffaeb;
@yellow-100: #fef0c7;
@yellow-200: #fedf89;
@yellow-300: #fec84b;
@yellow-400: #fdb022;
@yellow-500: #f79009;
@yellow-600: #dc6803;
@yellow-700: #b54708;
@yellow-800: #93370d;
@yellow-900: #7a2e0e;

@green-25: #f6fef9;
@green-50: #ecfdf3;
@green-100: #d1fadf;
@green-200: #a6f4c5;
@green-300: #6ce9a6;
@green-400: #32d583;
@green-500: #12b76a;
@green-600: #039855;
@green-700: #027a48;
@green-800: #05603a;
@green-900: #054f31;

@blue-25: #f5faff;
@blue-50: #eff8ff;
@blue-100: #d1e9ff;
@blue-200: #b2ddff;
@blue-300: #84caff;
@blue-400: #53b1fd;
@blue-500: #2e90fa;
@blue-600: #1570ef;
@blue-700: #175cd3;
@blue-800: #1849a9;
@blue-900: #194185;

@purple-50: #f4f3ff;
@purple-100: #ebe9fe;
@purple-500: #7a5af8;
@purple-600: #6938ef;
@purple-800: #4a1fb8;
@purple-900: #3e1c96;

@black: #000000;
@white: #ffffff;

//Tokens

@gradient: linear-gradient(3.76deg, @teal-100 -0.32%, @teal-25 100%);
@gradient-gray-50: linear-gradient(91.07deg, @gray-50 47.33%, @white 106.58%);

//Brand
@bosta-red: @red-600;
@deep-teal: @teal-500;
@light-aqua: @teal-400;

//Text
@text-gray-dark: @gray-900;
@text-gray: @gray-500;
@text-gray-light: @gray-300;
@text-white: @white;
@text-teal: @teal-500;
@text-red: @red-600;
@text-red-light: @red-400;
@text-red-dark: @red-900;
@text-green-dark: @green-900;
@text-blue-dark: @blue-900;

//Background
@bg-gray-light: @gray-50;
@bg-gray: @gray-200;
@bg-gray-dark: @gray-800;
@bg-teal-light: @teal-50;
@bg-red-light: @red-100;
@bg-overlay-dark: rgba(@gray-500, 0.6);
@bg-default: @white;
@bg-hovered: @gray-50;
@bg-pressed: @gray-100;
@bg-selected: @teal-100;
@bg-disabled: @gray-100;

//Icons
@icon-default: @gray-500;
@icon-subdued: @gray-400;
@icon-disabled: @gray-300;
@icon-red: @red-400;
@icon-red-dark: @red-700;
@icon-green: @green-600;
@icon-teal: @teal-600;
@icon-yellow: @yellow-400;
@icon-yellow-dark: @yellow-600;

//Borders
@border-all-sides: inset 1px 0px 0px @gray-200, inset -1px 0px 0px @gray-200,
  inset 0px -1px 0px @gray-200, inset 0px 1px 0px @gray-200;
@border-top-bottom: inset 0px -1px 0px @gray-200, inset 0px 1px 0px @gray-200;
@border-right-left: inset 1px 0px 0px @gray-200, inset -1px 0px 0px @gray-200;
@border-top: inset 0px 1px 0px @gray-200;
@border-bottom: inset 0px -1px 0px @gray-200;
@border-right: inset -1px 0px 0px @gray-200;
@border-left: inset 1px 0px 0px @gray-200;

//box-shadows
@shadow-xs: 0px 1px 2px rgba(@gray-800, 0.1);
@shadow-xs-green: 0px 1px 2px rgba(@gray-800, 0.1), 0px 0px 0px 4px @teal-200;
@shadow-xs-red: 0px 1px 2px rgba(@teal-200, 0.05), 0px 0px 0px 4px @red-100;
@shadow-sm: 0px 0px 1px 1px @gray-200, 0px 4px 4px rgba(@gray-800, 0.05);
@shadow-md: 0px 2px 4px rgba(@gray-800, 0.05), 0px 4px 16px rgba(@gray-800, 0.1);
@shadow-lg: 0px 0px 3px rgba(@gray-800, 0.1), 0px 4px 20px rgba(@gray-800, 0.15);
@shadow-xl: 0px 0px 4px rgba(@gray-800, 0.1), 0px 8px 40px rgba(@gray-800, 0.2);

//  Spacings
@None: 0;
@spacing-xs: 4px;
@spacing-sm: 8px;
@spacing-md: 12px;
@spacing-lg: 16px;
@spacing-xl: 24px;
@spacing-2xl: 32px;
@spacing-3xl: 48px;
@spacing-4xl: 56px;

//Break point
@2xs: 375px;
@xs: 576px;
@sm: 768px;
@md: 992px;
@lg: 1280px;
@xl: 1440px;
