@import 'styles/variables.less';

.br-hub-receive__tabs {
  margin-top: 24px;

  .ant-tabs-top.br-tabs__tab {
    border: 1px solid @gray-100;
    border-bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: @shadow-sm;
  }
}

.br-hub-receive__tabs {
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
  }
}
